<template>
    <v-container fluid fill-height style="justify-content: center">
        <div>
            <alert ref="alertComponent"></alert>
            <video-form ref="videoForm" @submit="submit($event)"></video-form>
        </div>
    </v-container>
</template>

<script>
import Alert from "../../components/Alert.component";
import VideoForm from "../../components/VideoForm.component";
import Services from '../../services/api';

export default {
    name: "NewVideo",
    components: {Alert, VideoForm},
    data: () => ({
        video: null
    }),
    methods: {
        /**
         * send the update request to the api
         * @returns {Promise<void>}
         */
        async submit(payload) {
            try {
                this.video = await Services.Videos.addVideo(payload);

                this.$refs.alertComponent.showSuccessMessage("Video successfully added");
                this.$refs.videoForm.setVideo(this.video);
            } catch (e) {
                this.$refs.alertComponent.showErrorMessage(e.message);
            }
            this.$refs.videoForm.resetDefault();
        }
    }
}
</script>

<style scoped>

</style>